import { GetApp } from "@mui/icons-material";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Imprint } from "../../../../../domain/struct/administration/Imprint";
import { DateTimeFormat } from "../../../../../lib/dateTime";
import { callAsyncAction } from "../../../../core/action";
import { getImprintAction } from "../../../../core/api/imprint/_actions";
import { BreadcrumbForTable } from "../../../../designSystem/Breadcrumb/Breadcrumb";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useFindDailyImprints } from "../../hooks/useFindDailyImprints";

const COLUMNS: ColumnType<Imprint>[] = [
  // createColumn({
  //   dataIndex: "id",
  //   title: t(translationPath(lang.general.id))
  // }),

  {
    dataIndex: "fileName",
    title: t(translationPath(lang.general.fileName)),
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  },
  createDateTimeColumn<Imprint>(
    {
      dataIndex: "createdAt",
      title: t(translationPath(lang.general.createdAt)),
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  )
];

export const DailyImprintTable = React.memo(() => {
  const dispatch = useDispatch();
  const findDailyImprints = useFindDailyImprints();

  const ACTIONS = useMemo(
    () => ({
      single: [
        {
          key: "download",
          icon: <GetApp />,
          tooltip: t(translationPath(lang.general.download)),
          action(selected: Imprint[]) {
            dispatch(
              callAsyncAction({
                action: getImprintAction,
                payload: {
                  nodeId: selected[0].id
                }
              })
            );
          }
        }
      ]
    }),
    [dispatch]
  );

  const getRemoteData: GetRemoteDataClb<Imprint> = useCallback(
    ({ pagination, sorting }) => findDailyImprints(pagination, sorting),
    [findDailyImprints]
  );

  return (
    <>
      <BreadcrumbForTable title={"dailyImprint"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<Imprint>
          name="admin/imprint"
          rowKey="id"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
        />
      </TableStyled>
    </>
  );
});

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
