import { TableRowSelection } from "antd/lib/table/interface";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { ColumnType } from "presentation/designSystem/Table/Table";
import { translationPath } from "presentation/share/utils/getPath";
import React, { useCallback, useMemo, useState } from "react";
import { Contact } from "../../../../../domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../../../domain/struct/nameRegistry/ContactType";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from "../../../../designSystem/Icon/Icon";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../designSystem/Table/RemoteTable";
import { createColumn } from "../../../../designSystem/Table/column/column";
import { lang, t } from "../../../../translation/i18n";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import { ContactDeleteModal } from "../../modal/ContactModal/ContactDeleteModal";
import { ContactModal } from "../../modal/ContactModal/ContactModal";

const COLUMNS: ColumnType<Contact>[] = [
  {
    dataIndex: "contact",
    title: t(translationPath(lang.table.contactTable.contact))
  },
  {
    dataIndex: "contactType",
    title: t(translationPath(lang.table.contactTable.contactType)),
    render(contactType: ContactType) {
      return t(`table:contactTable.${contactType.toLowerCase()}`);
    }
  }
];

interface ContactsTableProps {
  type: string;
}

export const ContactsTable = ({ type }: ContactsTableProps) => {
  const { persistence } = useSubjectModalContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const [contactModal, contactModalApi] = useModal(ContactModal, {
    onClose: () => {
      setSelectedRowKeys([]);
    }
  });
  const [contactDeleteModal, contactDeleteModalApi] = useModal(
    ContactDeleteModal,
    {
      onClose: () => {
        setSelectedRowKeys([]);
      }
    }
  );

  const ACTIONS = useMemo(
    () => ({
      default: [
        {
          key: "add-contact",
          icon: <PlusCircleOutlined rev={"default"} />,
          tooltip: "Přidat kontakt",
          action() {
            return contactModalApi.open({ type: type });
          }
        }
      ],
      single: [
        {
          key: "delete-contact",
          icon: <MinusCircleOutlined rev={"default"} />,
          tooltip: "Odebrat kontakt",
          async action(selected: Contact[]) {
            return contactDeleteModalApi.open({
              contact: selected[0]
            });
          }
        },
        {
          key: "edit-contact",
          icon: <EditOutlined rev={"default"} />,
          tooltip: "Upravit kontakt",
          action(dataSource: Contact[]) {
            return contactModalApi.open({
              contact: dataSource[0],
              type: type
            });
          }
        }
      ]
    }),
    [contactDeleteModalApi, contactModalApi]
  );

  const getRemoteData: GetRemoteDataClb<Contact> = useCallback(
    ({ pagination }) => persistence.getContacts(pagination),
    [persistence]
  );

  const onRowSelection: TableRowSelection<Contact> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      {contactModal}
      {contactDeleteModal}
      <RemoteTable<Contact>
        name={`nameRegistry/contactTable/${
          persistence.getSubjectData()?.subjectType
        }/${persistence.getSubjectData()?.id}`}
        actions={ACTIONS}
        columns={COLUMNS}
        getRemoteData={getRemoteData}
        rowSelection={onRowSelection}
      />
    </>
  );
};
