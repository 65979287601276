import { Block } from "@mui/icons-material";
import { User } from "domain/struct/administration/User";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  ColumnType,
  TableActions
} from "presentation/designSystem/Table/Table";
import { createColumn } from "presentation/designSystem/Table/column/column";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FindUser } from "../../../../../useCase/administration/FindUser";
import {
  openCreateUserDialogAction,
  openEditUserDialogAction
} from "../../../../core/api/user/_actions";
import { dialogOpenAction } from "../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../core/components/dialog/_types";
import { getService } from "../../../../core/features/dependencyInjection";
import { BreadcrumbForTable } from "../../../../designSystem/Breadcrumb/Breadcrumb";
import {
  actionAdd,
  actionShowDetail
} from "../../../../share/components/table/actionFactory";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { DisabledUsers } from "../../features/users/_types";

const COLUMNS: ColumnType<User>[] = [
  {
    dataIndex: "id",
    title: t(translationPath(lang.general.id))
  },
  {
    dataIndex: "firstName",
    title: t(translationPath(lang.dialog.renameComponent.name)),
    sorter: true,
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"]
  },
  {
    dataIndex: "email",
    title: t(translationPath(lang.general.email))
  },
  {
    dataIndex: "mainGroup",
    title: t(translationPath(lang.general.mainGroup))
  },
  {
    dataIndex: "enabled",
    title: t(translationPath(lang.general.deactivate)),
    render: (enabled) => {
      return enabled
        ? t(translationPath(lang.general.yes))
        : t(translationPath(lang.general.no));
    }
  }
];

const isRowDisabled = ({ id, enabled, readOnly }: User) => {
  return (
    id === DisabledUsers.Admin ||
    id === DisabledUsers.Databox ||
    id === DisabledUsers.Emailbox ||
    id === DisabledUsers.Guest ||
    id === DisabledUsers.SAdmin ||
    id === DisabledUsers.Spisum ||
    !enabled ||
    !!readOnly
  );
};

export const UserTable: React.FC = () => {
  const dispatch = useDispatch();
  const findUser = getService(FindUser);
  const getData: GetRemoteDataClb<User> = useCallback(
    ({ pagination, sorting }) => findUser.findAll(pagination, sorting),
    [findUser]
  );

  const ACTIONS: TableActions<User> = useMemo(
    () => ({
      default: [
        actionAdd({
          action() {
            dispatch(openCreateUserDialogAction());
          }
        })
      ],
      single: [
        actionShowDetail({
          action(selected) {
            dispatch(openEditUserDialogAction(selected[0]));
          },
          canBeDisplayed(selected) {
            return !!selected.enabled;
          }
        }),
        {
          key: "deactivate-user",
          tooltip: t(translationPath(lang.general.deactivate)),
          icon: <Block />,
          action(selected) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: selected[0]
                },
                dialogType: DialogType.DeactivateUser
              })
            );
          },
          canBeDisplayed(selected) {
            return !!selected.enabled;
          }
        }
      ]
    }),
    [dispatch]
  );

  return (
    <>
      <BreadcrumbForTable title={"users"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<User>
          name="administration/UserTable"
          columns={COLUMNS}
          actions={ACTIONS}
          getRemoteData={getData}
          isRowDisabled={isRowDisabled}
        />
      </TableStyled>
    </>
  );
};
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
