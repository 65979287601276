import React, { useCallback, useMemo } from "react";

import moment from "moment";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { lang, t } from "presentation/translation/i18n";
import { useSelector } from "react-redux";
import { LegalEntity } from "../../../../../domain/struct/nameRegistry/LegalEntity";
import { Person } from "../../../../../domain/struct/nameRegistry/Person";
import { Subject } from "../../../../../domain/struct/nameRegistry/Subject";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { useForm } from "../../../../designSystem/Form/v2/Form";
import { ModalWithTabs } from "../../../../designSystem/Modal/Modal";
import { Tabs } from "../../../../designSystem/Tabs/Tabs";
import { Notification } from "../../../../designSystem/notification/Notification";
import { ErrorCodeList, SpisumGroups } from "../../../../enums";
import { RootStateType } from "../../../../reducers";
import { useMutation } from "../../../../share/hook/query/useQM";
import { getErrorCodeTranslation } from "../../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../../share/utils/getPath";
import { ErrorType } from "../../../../types";
import {
  SubjectModalContextProvider,
  useSubjectModalContext
} from "../../contexts/SubjectModalContextProvider";
import {
  SubjectForm,
  SubjectFormOnChangeClb
} from "../../form/SubjectForm/SubjectForm";
import { useFindSubject } from "../../hooks/useFindSubject";
import { AddressesTable } from "../../tables/AddressesTable/AddressesTable";
import { ConcernedContentTable } from "../../tables/ConcernedContent/ConcernedContentTable";
import { ContactsTable } from "../../tables/ContactsTable/ContactsTable";
import { HistoryTable } from "../../tables/HistoryTable/HistoryTable";
import { useMuiVisibility } from "../SearchSubjectModal/useMuiVisibility";

export type SubjectModalProps =
  | {
      //form: FormInstance<SubjectWithProcessedAt>;
      type: SubjectType.Person;
      person?: Person;
      onCancel?: BaseModalProps["onCancel"];
      onOk?: (result?: Subject) => void;
    }
  | {
      type: SubjectType.LegalEntity;
      legalEntity?: LegalEntity;
      onCancel?: BaseModalProps["onCancel"];
      onOk?: (result?: Subject) => void;
    };

const TITLE_MAP = {
  create: t(translationPath(lang.dialog.subjectDialog.create)),
  edit: t(translationPath(lang.dialog.subjectDialog.edit))
};

export const SubjectModal = (props: SubjectModalProps) => {
  useMuiVisibility();
  const subject =
    props.type === SubjectType.Person ? props.person : props.legalEntity;

  const { data } = useFindSubject(props.type, subject?.id);

  const contextValue = useMemo(() => {
    return {
      subjectType: props.type, // set subject type in case when subject is undefined
      ...data
    };
  }, [props.type, data]);

  return (
    <SubjectModalContextProvider subject={contextValue}>
      <SubjectModalInner {...props} />
    </SubjectModalContextProvider>
  );
};

export const SubjectModalInner = (props: SubjectModalProps) => {
  const { setActiveTab, state, persistence, reset } = useSubjectModalContext();

  const [form] = useForm<
    Omit<Subject, "processedAt"> & { processedAt: moment.Moment }
  >();

  const { isLoading, mutate: validateAndSave } = useMutation(
    async () => {
      // we do not catch an error which can be thrown in save
      // so the onError callback can handle it
      const result = await persistence.save();
      onOk(result);
    },
    {
      onError(error: ErrorType) {
        if (error?.code) {
          return Notification.error({
            message: !ErrorCodeList.LegalSubjectExists.localeCompare(error.code)
              ? error.message
              : getErrorCodeTranslation(error.code)
          });
        } else if (
          error?.messages &&
          error.messages.every(
            (message) =>
              ErrorCodeList.SubjectMustHaveAtLeastOneContact.localeCompare(
                message
              ) ||
              ErrorCodeList.SubjectMustHaveAtLeastOneAddress.localeCompare(
                message
              )
          )
        ) {
          return Notification.error({
            message: error.messages
              .map((message) => getErrorCodeTranslation(message))
              .join(" ")
          });
        } else {
          Notification.error({
            message: t(
              translationPath(
                lang.dialog.notifications.notPossibleToSaveSubject
              )
            )
          });
        }
      },
      onSuccess() {
        Notification.success({
          message: t(translationPath(lang.dialog.notifications.subjectWasSaved))
        });
      },
      onErrorNotification: null,
      onSuccessNotification: null
    }
  );

  const handleSubjectFormChange: SubjectFormOnChangeClb = useCallback(
    (changedValues, values) => {
      persistence.setSubjectData(values);
    },
    [persistence]
  );

  const handleValidateAndSave = useCallback(async () => {
    try {
      await form.validateFields();
      validateAndSave(undefined);
    } catch {}
  }, [form, validateAndSave]);

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  const onCancel = () => {
    reset();
    persistence.resetState();
    props.onCancel?.();
  };

  const onOk = (result: any) => {
    reset();
    persistence.resetState();
    props.onOk?.({ ...result } as Subject);
  };

  const { activeGroup } = useSelector(
    (state: RootStateType) => state?.loginReducer?.session
  );

  const subject = persistence.getSubjectData();

  return (
    <ModalWithTabs
      open={true}
      title={subject?.id ? TITLE_MAP.edit : TITLE_MAP.create}
      onCancel={onCancel}
      onOk={handleValidateAndSave}
      confirmLoading={isLoading}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Tabs activeKey={state.activeTab} onChange={onTabChange}>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
          key={1}
        >
          <SubjectForm
            persistence={persistence}
            form={form as any}
            initialValues={subject}
            onChange={handleSubjectFormChange}
            activeGroup={activeGroup}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.subjectDialog.tabs.addresses))}
          key={2}
        >
          <AddressesTable />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.subjectDialog.tabs.contacts))}
          key={3}
        >
          <ContactsTable
            type={persistence?.getSubjectData()?.subjectType ?? ""}
          />
        </Tabs.TabPane>
        {subject?.id && activeGroup === SpisumGroups.NameRegister && (
          <>
            <Tabs.TabPane
              tab={t(
                translationPath(lang.dialog.subjectDialog.tabs.concernedContent)
              )}
              key={4}
            >
              <ConcernedContentTable
                subjectId={persistence.getSubjectData()?.id ?? ""}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t(translationPath(lang.dialog.subjectDialog.tabs.history))}
              key={5}
            >
              <HistoryTable
                subjectId={persistence.getSubjectData()?.id ?? ""}
              />
            </Tabs.TabPane>
          </>
        )}
      </Tabs>
    </ModalWithTabs>
  );
};
