import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import {
  BaseModalProps,
  useModal
} from "@isfg/react-components/build/Modal/useModal";
import { createColumn } from "@isfg/react-components/build/Table/column/column";
import { Block } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import Publish from "@mui/icons-material/Publish";
import { TableRowSelection } from "antd/lib/table/interface";
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import { DA_ALLOWED_EXTENSIONS } from "presentation/core/api/daimport/constants";
import { ComponentUploadAnt } from "presentation/core/components/dialog/tabs/components/ComponentUploadAnt";
import { Button } from "presentation/designSystem/Button/Button";
import { ModalWithRefresh } from "presentation/designSystem/Modal/ModalWithRefresh";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  ColumnType,
  TableActions,
  useRemoteTableApi
} from "presentation/designSystem/Table/Table";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { useMutation } from "presentation/share/hook/query/useQM";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ErrorType } from "presentation/types";
import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  DeactivateValueParams,
  getCodeListValues,
  postCodeListExport,
  postUploadValue
} from "../api";
import { CodeListValueResponse } from "../types";
import { CreateNewValue } from "./CreateNewValue";
import { DeactivateValueModal } from "./DeactivateValueModal";

export interface CodeListDetailModalProps {
  onCancel?: BaseModalProps["onCancel"];
  codeListType: string;
  remoteQueryParams?: Record<string, string>;
}

export const CodeListDetailModal = (props: CodeListDetailModalProps) => {
  return (
    <RemoteTableApiContextProvider>
      <CodeListDetailModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const CodeListDetailModalInner = ({
  onCancel,
  codeListType,
  remoteQueryParams
}: CodeListDetailModalProps) => {
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [createNewValueModal, createNewValueApi] = useModal(CreateNewValue);
  const [deactivateValueModal, deactivateValueModalApi] =
    useModal(DeactivateValueModal);
  const fileRef = useRef<HTMLInputElement>(null);

  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.code
        ? getErrorCodeTranslation(error.code)
        : t(translationPath(lang.dialog.notifications.actionFailed))
    };
  };

  const { mutate: exportValues } = useMutation(
    () => postCodeListExport(codeListType),
    {
      onErrorNotification: getNotificationForError
    }
  );

  const onExport = useCallback(async () => {
    try {
      await exportValues(codeListType);
      postCodeListExport(codeListType).then(({ response, headers }) => {
        const disposition = contentDisposition.parse(
          headers?.get("content-disposition") || ""
        );
        fileDownload(response as Blob, disposition.parameters.filename);
      });
    } catch {}
  }, [codeListType, exportValues]);

  const getRemoteData: GetRemoteDataClb<CodeListValueResponse> = useCallback(
    ({ pagination, sorting }) =>
      getCodeListValues(codeListType, pagination, sorting),
    [codeListType]
  );

  const { mutate: uploadValue } = useMutation(
    (file: File) => postUploadValue(codeListType, file),
    {
      onSuccess() {
        tableApi.refetch();
      },
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.valueUploaded))
      },
      onErrorNotification: getNotificationForError
    }
  );

  const handleUploadValue = useCallback(
    async (files: globalThis.File[]) => {
      try {
        await uploadValue(files[0]!);
      } catch {}
    },
    [uploadValue]
  );

  const ACTIONS: TableActions<CodeListValueResponse> = useMemo(
    () => ({
      default: [
        {
          key: "import-codelist-value",
          customControl: (
            <ComponentUploadAnt
              ref={fileRef}
              allowedExtensions={DA_ALLOWED_EXTENSIONS}
              multipleFilesAllowed={false}
              handleUploadComponent={handleUploadValue}
              title={t(translationPath(lang.general.import))}
              icon={<Publish />}
            />
          )
        },
        {
          key: "export-codelist-value",
          icon: <GetAppIcon />,
          tooltip: t(translationPath(lang.general.export)),
          action() {
            onExport();
          }
        },
        {
          key: "create-codelist-value",
          icon: <PlusCircleOutlined rev={"default"} />,
          tooltip: t(translationPath(lang.dialog.title.createNewValue)),
          action() {
            return createNewValueApi.open({ codeListType: codeListType });
          }
        }
      ],
      single: [
        {
          key: "deactivate-value",
          action(selected: DeactivateValueParams[]) {
            return deactivateValueModalApi.open({
              value: selected[0]?.value,
              codeList: codeListType
            });
          },
          icon: <Block />,
          tooltip: t(translationPath(lang.general.deactivateValue))
        }
      ],
      multi: [
        {
          key: "deactivate-multi-value",
          action(selected: DeactivateValueParams[]) {
            return deactivateValueModalApi.open({
              value: selected[0]?.value,
              codeList: codeListType
            });
          },
          icon: <Block />,
          tooltip: t(translationPath(lang.general.deactivateValue))
        }
      ]
    }),
    [
      codeListType,
      createNewValueApi,
      deactivateValueModalApi,
      handleUploadValue,
      onExport
    ]
  );

  const COLUMNS: ColumnType<CodeListValueResponse>[] = [
    {
      dataIndex: "value",
      title: t(translationPath(lang.general.value))
    }
  ];

  const onRowSelection: TableRowSelection<CodeListValueResponse> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys,
    type: "checkbox"
  };

  return (
    <>
      {createNewValueModal}
      {deactivateValueModal}
      <ModalWithRefresh
        open={true}
        title={t(translationPath(lang.dialog.title.codeListValueDetail))}
        onRefresh={tableApi.refetch}
        onCancel={onCancel}
        confirmLoading={false}
        footer={[
          <Button key="back" onClick={onCancel}>
            {t(translationPath(lang.modal.close))}
          </Button>
        ]}
      >
        <TableStyled>
          <RemoteTable<CodeListValueResponse>
            rowKey="value"
            name="admin/codeListDetail"
            actions={ACTIONS}
            columns={COLUMNS}
            remoteQueryParams={remoteQueryParams}
            getRemoteData={getRemoteData}
            onRemoteTableApi={onRemoteTableApi}
            rowSelection={onRowSelection}
          />
        </TableStyled>
      </ModalWithRefresh>
    </>
  );
};

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
