import React, { useEffect, useState } from "react";

import {
  Form,
  FormInstance,
  Item
} from "../../../../designSystem/Form/v2/Form";
import { ColOneFourth, Row } from "../../../../designSystem/Grid/Grid";
import { SubjectTypeInput } from "./Input/SubjectTypeInput";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { PersonFields } from "./PersonFields";
import { LegalEntityFields } from "./LegalEntityFields";
import { Subject } from "../../../../../domain/struct/nameRegistry/Subject";
import { isPerson } from "domain/struct/nameRegistry/Person";
import { Input } from "../../../../designSystem/Input/Input";
import { SpisumGroups } from "../../../../enums";
import { DatePicker } from "../../../../designSystem/DatePicker/DatePicker";
import { DateTimeFormat } from "../../../../../lib/dateTime";
import { SubjectPersistenceLogic } from "../../modal/SubjectModal/lib/SubjectPersistenceLogic";
import moment from "moment";

import { Moment } from "moment";

export interface SubjectFormProps {
  form: FormInstance<Omit<Subject, "processedAt"> & Moment>;
  initialValues?: Partial<Subject>;
  onChange: SubjectFormOnChangeClb;
  activeGroup?: string;
  persistence: SubjectPersistenceLogic;
}

export type SubjectFormOnChangeClb = (
  changedValues: Partial<Subject>,
  values: Subject
) => void;

const DEFAULT_CITIZENSHIP = "CZE";

export const SubjectForm = ({
  form,
  persistence,
  initialValues,
  onChange,
  activeGroup
}: SubjectFormProps) => {
  const [selectedSubjectType, setSelectedSubjectType] = useState(
    initialValues?.subjectType
  );
  const [disableSubjectType, setDisableSubjectType] = useState(false);
  const addresses = persistence.getAddressesFromState();
  const contacts = persistence.getContactsFromState();

  useEffect(() => {
    setDisableSubjectType(persistence?.disableSubjectTypeSelect());
  }, [selectedSubjectType, addresses, contacts, persistence]);

  useEffect(() => {
    setSelectedSubjectType(initialValues?.subjectType);
  }, [initialValues?.subjectType]);

  useEffect(() => {
    persistence.setSubjectData({ subjectType: selectedSubjectType! });
  }, [selectedSubjectType, persistence]);

  useEffect(() => {
    const values = {
      citizenship: DEFAULT_CITIZENSHIP,
      ...initialValues,
      subjectType: selectedSubjectType,
      processedAt: moment(initialValues?.processedAt),
      ...(isPerson(initialValues) && {
        bornDate: initialValues.bornDate ? moment(initialValues.bornDate) : null
      })
    };

    form.setFieldsValue(values);
  }, [form, initialValues, selectedSubjectType]);

  return (
    <Form
      form={form as any}
      name="subjectForm"
      onValuesChange={onChange}
      initialValues={initialValues}
    >
      <Row justify="space-between">
        {initialValues?.id && activeGroup === SpisumGroups.NameRegister && (
          <ColOneFourth spaceAround>
            <Item name="identifier" label="Identifikátor">
              <Input disabled={true} />
            </Item>
          </ColOneFourth>
        )}
        {initialValues?.id && (
          <ColOneFourth spaceAround>
            <Item name="processedAt" label="Datum vytvoření">
              <DatePicker
                disabled={true}
                format={DateTimeFormat.DateTimeNoSeconds}
              />
            </Item>
          </ColOneFourth>
        )}
        <ColOneFourth spaceAround>
          <SubjectTypeInput
            onChange={setSelectedSubjectType}
            readonly={disableSubjectType}
          />
        </ColOneFourth>
        {activeGroup !== SpisumGroups.NameRegister && (
          <ColOneFourth spaceAround />
        )}
        <ColOneFourth spaceAround />
      </Row>
      {selectedSubjectType === SubjectType.Person && <PersonFields />}
      {selectedSubjectType === SubjectType.LegalEntity && (
        <LegalEntityFields disableVat={!!persistence?.getSubjectData()?.id} />
      )}
    </Form>
  );
};
