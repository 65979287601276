import React, { useCallback } from "react";
import { ColumnType } from "../../../../designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "../../../../designSystem/Table/column/column";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../designSystem/Table/RemoteTable";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";
import { RetentionProtocolProposalContent } from "../../../../../domain/struct/recordRetention/RetentionProtocolProposalContent";
import { useFindRetentionProtocolOrProposalHistory } from "../../hooks/useFindRetentionProtocolOrProposalHistory";
import { RetentionProtocolProposalHistory } from "../../../../../domain/struct/recordRetention/RetentionProtocolProposalHistory";
import { DateTimeFormat } from "../../../../../lib/dateTime";

const COLUMNS: ColumnType<RetentionProtocolProposalHistory>[] = [
  {
    dataIndex: "pid",
    title: t(
      translationPath(
        lang.table.retentionProposalOrProtocolHistoryTable.identifier
      )
    )
  },
  createDateTimeColumn<RetentionProtocolProposalHistory>(
    {
      dataIndex: "occurredAt",
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,

      title: t(
        translationPath(
          lang.table.retentionProposalOrProtocolHistoryTable.dateOfChange
        )
      )
    },
    DateTimeFormat.FullDateTime
  ),
  {
    dataIndex: "description",
    title: t(
      translationPath(
        lang.table.retentionProposalOrProtocolHistoryTable.description
      )
    )
  },
  {
    dataIndex: "userId",
    title: t(
      translationPath(
        lang.table.retentionProposalOrProtocolHistoryTable.authorOfChange
      )
    )
  },
  {
    dataIndex: "eventType",
    title: t(
      translationPath(
        lang.table.retentionProposalOrProtocolHistoryTable.typeOfChange
      )
    )
  }
];

interface RetentionProposalOrProtocolHistoryTableInterface {
  id: string;
}

export const RetentionProposalOrProtocolHistoryTable = ({
  id
}: RetentionProposalOrProtocolHistoryTableInterface) => {
  const findProtocolsOrProposals =
    useFindRetentionProtocolOrProposalHistory(id);

  const getRemoteData: GetRemoteDataClb<RetentionProtocolProposalContent> =
    useCallback(
      ({ pagination, sorting }) =>
        findProtocolsOrProposals(pagination, sorting),
      [findProtocolsOrProposals]
    );

  return (
    <>
      <RemoteTable<RetentionProtocolProposalHistory>
        name={`recordRetention/retentionProposalOrProtocolHistoryTable${id}`}
        columns={COLUMNS}
        getRemoteData={getRemoteData}
      />
    </>
  );
};
