import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import React, { useCallback } from "react";
import styled from "styled-components";
import { ConversionAll } from "../../../../../domain/struct/administration/ConversionAll";
import { DateTimeFormat } from "../../../../../lib/dateTime";
import { BreadcrumbForTable } from "../../../../designSystem/Breadcrumb/Breadcrumb";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useFindConversionAll } from "../../hooks/useFindConversionAll";

const COLUMNS: ColumnType<ConversionAll>[] = [
  {
    dataIndex: "conversionId",
    title: t(translationPath(lang.general.givenConversion)),
    sorter: true,
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
    render: (conversionId) => {
      return <div>{conversionId + "."}</div>;
    }
  },
  createDateTimeColumn<ConversionAll>(
    {
      dataIndex: "conversionDate",
      title: t(translationPath(lang.general.conversionDate))
    },
    DateTimeFormat.Date
  ),
  {
    dataIndex: "type",
    render: (type: string) => {
      return type.toUpperCase();
    },
    title: t(translationPath(lang.general.conversionType))
  },
  {
    dataIndex: "ssid",
    title: t(translationPath(lang.general.conversionDocumentSsid))
  },
  createDateTimeColumn<ConversionAll>(
    {
      dataIndex: "conversionDate",
      title: t(translationPath(lang.general.conversionTime))
    },
    DateTimeFormat.HoursMinutesSeconds
  ),
  {
    dataIndex: "component",
    title: t(translationPath(lang.general.component))
  },
  {
    dataIndex: "author",
    title: t(translationPath(lang.general.conversionAuthorFullName))
  },
  createDateTimeColumn<ConversionAll>(
    {
      dataIndex: "removalDate",
      title: t(translationPath(lang.general.removalDate))
    },
    DateTimeFormat.Date
  )
];

export const ConversionAllTable = React.memo(() => {
  const findConversionAll = useFindConversionAll();

  const getRemoteData: GetRemoteDataClb<ConversionAll> = useCallback(
    ({ pagination, sorting }) => findConversionAll(pagination, sorting),
    [findConversionAll]
  );

  return (
    <>
      <BreadcrumbForTable title={"conversion"}></BreadcrumbForTable>

      <TableStyled>
        <RemoteTable<ConversionAll>
          style={{ whiteSpace: "pre" }}
          name="admin/conversion"
          rowKey="conversionId"
          columns={COLUMNS}
          getRemoteData={getRemoteData}
        />
      </TableStyled>
    </>
  );
});

const TableStyled = styled("div")`
  div > .ant-table-wrapper {
    margin-top: 5%;
  }
`;
