import { useModal } from "@isfg/react-components/build/Modal/useModal";
import { Description } from "@mui/icons-material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import { TableRowSelection } from "antd/lib/table/interface";
import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import { SpisumNodeTypes } from "presentation/enums";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { dialogOpenAction } from "../../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../../core/components/dialog/_types";
import { useFindSignatureBookForSeal } from "../hooks/useFindSignatureBookForSeal";
import { DocumentsForSealModal } from "../modal/DocumentsForSealModal";
import { ForSeal } from "../types/ForSeal";

import { DateTimeFormat } from "lib/dateTime";
import { BreadcrumbForTable } from "presentation/designSystem/Breadcrumb/Breadcrumb";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  ColumnType,
  useRemoteTableApi
} from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import styled from "styled-components";

const COLUMNS: ColumnType<ForSeal>[] = [
  createColumn<ForSeal>({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn<ForSeal>({
    dataIndex: "ssid",
    title: t(translationPath(lang.general.referenceNumber)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn<ForSeal>({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject)),
    sortDirections: ["ascend", "descend", "ascend"]
  }),
  createColumn<ForSeal>({
    dataIndex: "attachmentsCount",
    title: t(translationPath(lang.general.attachmentsCount)),
    sortDirections: ["ascend", "descend", "ascend"]
  }),
  createColumn<ForSeal>({
    dataIndex: "owner",
    title: t(translationPath(lang.general.owner)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn<ForSeal>(
    {
      dataIndex: "forSealDate",
      title: t(translationPath(lang.general.signDocumentForSealDate)),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  )
];

export const ForSealTable = React.memo(() => {
  const dispatch = useDispatch();
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();
  const findSignatureBookForSeal = useFindSignatureBookForSeal();
  const [modal, modalApi] = useModal(DocumentsForSealModal, {
    onClose() {
      setSelectedRowKeys([]);
    }
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const ACTIONS = useMemo(
    () => ({
      single: [
        {
          key: "signature-book-for-seal-detail",
          icon: <Description />,
          tooltip: t(translationPath(lang.general.showDetails)),
          action(selected: ForSeal[]) {
            dispatch(
              openDocumentWithSaveButtonsAction({
                data: {
                  id: selected[0].nodeId
                },
                canUploadComponents: false,
                hideManageShipmentsIcon: true,
                initiator: SpisumNodeTypes.Document,
                isReadonly: true
              })
            );
          }
        },
        {
          key: "signature-book-for-sealing",
          icon: <BrightnessHighIcon />,
          tooltip: t(translationPath(lang.general.forSeal)),
          action(selected: ForSeal[]) {
            return modalApi.open({
              nodeId: selected[0].nodeId!,
              nodeType: SpisumNodeTypes.Document,
              dialogType: "documentsForSeal"
            });
          }
        },
        {
          key: "signature-book-for-seal-return-for-work",
          icon: <BackspaceIcon />,
          tooltip: t(translationPath(lang.general.sealReturnForRework)),
          canBeDisplayed: (selected: ForSeal) => !!selected.nodeId,
          action(selected: ForSeal[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    ...selected[0],
                    id: selected[0].nodeId
                  },
                  onClose: () => {
                    setSelectedRowKeys([]);
                    tableApi.refetch();
                  }
                },
                dialogType: DialogType.ReturnForRework
              })
            );
          }
        }
      ]
    }),
    [dispatch, modalApi, tableApi]
  );

  const onRowSelection: TableRowSelection<ForSeal> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  const getRemoteData: GetRemoteDataClb<ForSeal> = useCallback(
    ({ pagination, sorting }) => findSignatureBookForSeal(pagination, sorting),
    [findSignatureBookForSeal]
  );

  return (
    <>
      {modal}
      <BreadcrumbForTable title={"documentForSeal"} />
      <TableStyled>
        <RemoteTable<ForSeal>
          name="signatureBook/SignatureBookForSeal"
          rowKey="pid"
          rowSelection={onRowSelection}
          actions={ACTIONS}
          columns={COLUMNS}
          onRemoteTableApi={onRemoteTableApi}
          getRemoteData={getRemoteData}
        />
      </TableStyled>
    </>
  );
});

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
