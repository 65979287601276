import React from "react";
import { Dayjs } from "dayjs";
import { DateTime, DateTimeFormat } from "lib/dateTime";
import moment from "moment";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import {
  Form,
  FormInstance,
  Item
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { positiveNumbers } from "presentation/designSystem/Input/InputValidations";
import { Select } from "presentation/designSystem/Select/Select";
import { DeliveryMode } from "presentation/enums";
import { SenderFormNewProps } from "presentation/share/components/form/SenderFormAnt";
import { useSenderForm } from "presentation/share/components/form/hooks/useSenderForm";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import {
  TechnicalDataCarrierDocument,
  UpdateTechnDataCarrierFormFieldTypes
} from "../types/types";

const validation = [{ required: true }];

interface TechnicalDataCarrierFormProps {
  formData: TechnicalDataCarrierDocument;
  form: FormInstance<UpdateTechnDataCarrierFormFieldTypes>;
  onSenderChange: SenderFormNewProps["onSenderChange"];
}

export const TechnicalDataCarrierForm = ({
  formData,
  form,
  onSenderChange
}: TechnicalDataCarrierFormProps) => {
  const deliveryDate = moment(formData.deliveryDate!);
  const { senderFormData, senderForm } = useSenderForm({
    senderData: formData.sender,
    form: form as any, // Type casting FormInstance<SenderFormFieldsValues>
    onSenderChange
  });
  const formInitialValues = {
    ...formData,
    deliveryDate: moment(deliveryDate),
    settleToDate: formData.settleToDate
      ? moment(formData.settleToDate)
      : undefined,
    ...senderFormData
  };

  const disabledDate = (current: moment.Moment) => {
    return current.isAfter(moment(), "day");
  };

  const disabledTime = (current: moment.Moment) => {
    const { deliveryDate } = form.getFieldsValue();
    return deliveryDate?.isSame(moment(), "day") && current.isAfter(moment());
  };

  const onDateChange = (value: moment.Moment | null) => {
    if (value?.isSame(moment(), "day")) {
      form.setFieldsValue({
        deliveryDate: moment()
      });
    }
  };

  return (
    <>
      <Form name="form" form={form} initialValues={formInitialValues}>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="deliveryDate"
              label={t(translationPath(lang.documentMetaForm.deliveryDate))}
              rules={[{ required: true }]}
              data-test-id="delivery-date-technical-carrier-name"
            >
              <DatePicker
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
                disabledDate={(current: Dayjs) =>
                  disabledDate(moment(current as DateTime))
                }
                data-test-id="delivery-date-technical-carrier"
                onChange={onDateChange}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="deliveryDate"
              label={t(translationPath(lang.general.deliveryTime))}
              data-test-id="delivery-time-technical-carrier-name"
            >
              <DatePicker
                picker="time"
                disabledDate={(current: Dayjs) =>
                  disabledTime(moment(current as DateTime))
                }
                format={DateTimeFormat.HoursMinutesSeconds}
                style={{ width: "100%" }}
                data-test-id="delivery-time-technical-carrier"
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="deliveryMode"
              label={t(translationPath(lang.general.deliveryMode))}
              required={true}
              rules={[{ required: true }]}
              data-test-id="delivery-mode-technical-carrier"
            >
              <Select>
                <Select.Option value={DeliveryMode.Currier}>
                  {t(translationPath(lang.enums.deliveryMode.currier))}
                </Select.Option>
                <Select.Option value={DeliveryMode.Personally}>
                  {t(translationPath(lang.enums.deliveryMode.personally))}
                </Select.Option>
                <Select.Option value={DeliveryMode.Post}>
                  {t(translationPath(lang.enums.deliveryMode.post))}
                </Select.Option>
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="pid"
              label={t(translationPath(lang.documentMetaForm.pid))}
              required={true}
              rules={validation}
              data-test-id="pid-technical-carrier-name"
            >
              <Input disabled data-test-id="pid-technical-carrier" />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              label={t(translationPath(lang.documentMetaForm.form))}
              data-test-id="form-technical-carrier-name"
            >
              <Select
                disabled
                defaultValue={t(translationPath(lang.general.digital))}
                data-test-id="form-technical-carrier"
              ></Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="attachmentsCount"
              label={t(translationPath(lang.general.attachmentsCount))}
              rules={[positiveNumbers]}
              data-test-id="attachment-count-technical-carrier-name"
            >
              <Input
                type="number"
                min="0"
                data-test-id="attachment-count-technical-carrier"
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="attachmentsType"
              label={t(translationPath(lang.general.attachmentsType))}
              data-test-id="attachment-type-technical-carrier-name"
            >
              <Input
                data-test-id="attachment-type-technical-carrier"
                maxLength={50}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="settleToDate"
              label={t(translationPath(lang.general.settleToDate))}
              data-test-id="settle-to-date-technical-carrier-name"
            >
              <DatePicker
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  return current < moment().add(-1, "days");
                }}
                data-test-id="settle-to-date-technical-carrier"
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="senderIdent"
              label={t(translationPath(lang.general.senderIdent))}
              data-test-id="sender-id-technical-carrier-name"
            >
              <Input
                data-test-id="sender-id-technical-carrier"
                maxLength={50}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="senderSsid"
              label={t(translationPath(lang.general.senderSSID))}
              data-test-id="sender-ssid-technical-carrier-name"
            >
              <Input
                data-test-id="sender-ssid-technical-carrier"
                maxLength={50}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name="subject"
              label={t(translationPath(lang.general.subject))}
              data-test-id="subject-technical-carrier-name"
            >
              <Input data-test-id="subject-technical-carrier" maxLength={255} />
            </Item>
          </Col>
        </Row>
        {senderForm}
      </Form>
    </>
  );
};
