import React, { useCallback, useMemo } from "react";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../../../designSystem/Table/RemoteTable";
import { lang, t } from "../../../../../../translation/i18n";
import { translationPath } from "../../../../../../share/utils/getPath";
import { FilePlanColumnValue } from "../../../../../../share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { FileMarkColumnValue } from "../../../../../../share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { RetentionArchivedOrDestroyed } from "../../../../../../../domain/struct/recordRetention/RetentionArchivedOrDestroyed";
import { useFindRetentionArchived } from "../../../../hooks/useFindRetentionArchived";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { DetailsOfErasedDocumentModal } from "../../../../modal/DetailsOfErasedDocumentModal";
import { DetailsOfErasedFileModal } from "../../../../modal/DetailsOfErasedFileModal";
import { actionShowDetail } from "../../../../../../share/components/table/actionFactory";
import { SpisumNodeTypes } from "../../../../../../enums";

const COLUMNS: ColumnType<RetentionArchivedOrDestroyed>[] = [
  {
    dataIndex: "pid",
    title: t(translationPath(lang.table.retentionArchivedTable.identifier))
  },
  {
    dataIndex: "ssidFileIdent",
    title: t(translationPath(lang.table.retentionArchivedTable.ssidFileIdent))
  },
  {
    dataIndex: "subject",
    title: t(translationPath(lang.table.retentionArchivedTable.subject))
  },
  {
    dataIndex: "filePlan",
    title: t(translationPath(lang.table.retentionArchivedTable.filePlan)),
    render: (text, row) => {
      const { filePlan } = row;

      if (!filePlan) {
        return;
      }

      return <FilePlanColumnValue filePlanId={filePlan} />;
    }
  },
  {
    dataIndex: "fileMark",
    title: t(translationPath(lang.table.retentionArchivedTable.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;

      if (!fileMark) {
        return;
      }

      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  },
  {
    dataIndex: "retentionMode",
    title: t(translationPath(lang.table.retentionArchivedTable.retentionMode))
  },
  {
    dataIndex: "identifierDa",
    title: t(translationPath(lang.table.retentionArchivedTable.identifierDA))
  }
];

interface RetentionArchivedTableInterface {
  id: string;
}

export const RetentionArchivedTable = ({
  id
}: RetentionArchivedTableInterface) => {
  const findArchived = useFindRetentionArchived(id);

  const [documentModal, documentModalApi] = useModal(
    DetailsOfErasedDocumentModal
  );
  const [fileModal, fileModalApi] = useModal(DetailsOfErasedFileModal);

  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: RetentionArchivedOrDestroyed[]) {
            const selectedNodeId = selected[0]?.nodeId;

            if (selectedNodeId) {
              if (selected[0]?.nodeType === SpisumNodeTypes.DocumentArchived) {
                documentModalApi.open({
                  nodeId: selectedNodeId
                });
              } else {
                fileModalApi.open({
                  nodeId: selectedNodeId
                });
              }
            }
          }
        })
      ]
    }),
    [documentModalApi, fileModalApi]
  );

  const getRemoteData: GetRemoteDataClb<RetentionArchivedOrDestroyed> =
    useCallback(({ pagination }) => findArchived(pagination), [findArchived]);

  return (
    <>
      {documentModal}
      {fileModal}
      <RemoteTable<RetentionArchivedOrDestroyed>
        name={`recordRetention/retentionArchivedTable${id}`}
        columns={COLUMNS}
        actions={ACTIONS}
        getRemoteData={getRemoteData}
        rowKey="nodeId"
      />
    </>
  );
};
