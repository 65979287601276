import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../designSystem/Table/RemoteTable";
import React, { useCallback, useEffect, useMemo } from "react";
import { ColumnType, TableActions } from "../../../designSystem/Table/Table";
import { createColumn } from "../../../designSystem/Table/column/column";
import { actionShowDetail } from "../../components/table/actionFactory";
import { useFileConcernedSubjectLogic } from "./hooks/useFileConcernedSubjectLogic";
import { FileConcernedSubject } from "../../../../domain/struct/nameRegistry/FileConcernedSubject";
import { useFindFileConcernedSubjects } from "./hooks/useFindFileConcernedSubjects";
import { t } from "../../../translation/i18n";
import { translationPath } from "../../utils/getPath";
import lang from "../../../translation/lang";
import { getRelationTypeTranslation } from "../document/ConcernedSubjectTable/helper";
import { TabAndDialogChannelType } from "../../../core/components/dialog/_types";
import { useRemoteTableApi } from "../../../designSystem/Table/hook/useRemoteTableApi";

const COLUMNS: ColumnType<FileConcernedSubject>[] = [
  {
    dataIndex: ["subject", "fullName"],
    title: t(translationPath(lang.table.concernedSubjectTable.subjectName))
  },
  {
    dataIndex: ["subject", "subjectType"],
    title: t(translationPath(lang.table.concernedSubjectTable.subjectType))
  },
  {
    title: t(translationPath(lang.table.concernedSubjectTable.relationType)),
    render(value, record, index) {
      const [first] = record.documentSubjectAssociations || [];

      if (first?.relationType) {
        return getRelationTypeTranslation(first.relationType);
      }

      return "";
    }
  },
  {
    title: t(
      translationPath(lang.table.fileConcernedSubjectTable.concernedContent)
    ),
    render(value, record, index) {
      return record.documentSubjectAssociations?.map(({ documentPid }) => (
        <div key={documentPid}>{documentPid}</div>
      ));
    }
  }
];

export interface FileConcernedSubjectTableProps {
  documentId: string;
  channel?: TabAndDialogChannelType;
}

export const FileConcernedSubjectTable = ({
  documentId,
  channel
}: FileConcernedSubjectTableProps) => {
  const [tableApi, setTableApi] = useRemoteTableApi();

  const findFileConcernedSubjects = useFindFileConcernedSubjects();

  const getSubjects: GetRemoteDataClb<FileConcernedSubject> = useCallback(
    ({ pagination }) => findFileConcernedSubjects(documentId, pagination),
    [documentId, findFileConcernedSubjects]
  );

  const { modal, onShowDetail } = useFileConcernedSubjectLogic();

  useEffect(() => {
    if (channel && !channel.refreshData) {
      channel.refreshData = tableApi.refetch;
    }
  }, [channel, tableApi.refetch]);

  const ACTIONS: TableActions<FileConcernedSubject> = useMemo(() => {
    return {
      default: [],
      single: [
        actionShowDetail({
          action: onShowDetail
        })
      ]
    };
  }, [onShowDetail]);

  return (
    <>
      {modal}
      <RemoteTable<FileConcernedSubject>
        name={`nameRegister/fileConcernedSubject/${documentId}`}
        columns={COLUMNS}
        actions={ACTIONS}
        getRemoteData={getSubjects}
        onRemoteTableApi={setTableApi}
      />
    </>
  );
};
